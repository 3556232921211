.head-contact {
	$className: head-contact;

	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	line-height: 1.4;

	padding-right: rfs-fluid-value($header-padding);
	padding-left: rfs-fluid-value($header-padding);

	@each $breakpoint, $size in $header-height {
		@include media-breakpoint-up($breakpoint) {
			height: $size;
		}
	}

	&:hover {
		color: #fff;
	}

	&__label {
		font-size: 15px;
		font-weight: 400;
	}

	&__value {
		font-size: 25px;
		font-weight: 600;
	}

	&__icon {
		font-size: 36px;
		margin-left: 20px;
		transition: all ease 0.3s;
	}

}
