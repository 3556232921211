.reference-list {

	&__item {
		border: 1px solid $gray-300;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__image {
		max-width: 80%;
	}

}
