.contact-address {

	position: absolute;
	right: 0;
	top: 50%;
	margin-top: 250px / 2 * -1;

	background: $primary;
	max-width: 420px;
	width: 100%;
	padding: 30px 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 250px;

	&__item {
		display: flex;
		margin: 10px 0;
	}

	&__icon {
		font-size: 20px;
		color: #fff;
		margin-right: 20px;
		padding: 4px 0;
	}

	&__text {
		font-size: 20px;
		line-height: 1.2;
		color: #fff;

		&:hover {
			text-decoration: underline;
			color: #fff;
		}
	}


	@include media-breakpoint-down(md) {
		position: relative;
		margin-top: 0;
		top: 0;
		height: auto;
		padding: 20px 30px;
	}

}
