.foot-copyright {
	&__image {
	}

	&__text {
		font-size: 14px;
		color: $gray-500;
	}
}

