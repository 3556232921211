.fw-medium {
	font-weight: $font-weight-medium;
}

/* Text Truncate */
@for $i from 1 through 4 {
	@for $k from 14 through 20 {
		.text-truncate-#{$i}-#{$k} {
			@include shortText($k, $line-height-base, $i);
		}
	}
}

/* Font Family */
@include getFonts($ff-name, $ff-sizes);

/* Rotate */
@each $rotate, $var in $rotates {
	.rotate-#{$rotate} {
		transform: rotate($var);
	}
}

/* Opacity */
@for $i from 0 through 9 {
	.opacity-#{$i} {
		opacity: #{$i / 10} !important;
	}
}

/* Border Radius */
@for $i from 0 through 20 {
	.border-radius-#{$i} {
		border-radius: #{$i}px !important;
	}
}

/* Position: top, bottom, left, right */
@each $prop, $abbrev in (top: t, bottom: b, right: r, left: l) {
	@each $size, $length in $spacers {
		.#{$abbrev}-#{$size} {
			#{$prop}: $length !important;
		}
		.#{$abbrev}-n#{$size} {
			#{$prop}: -$length !important;
		}
	}
}

/* Font Size & Line Height */
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		@for $i from 8 through 64 {
			.fz#{$infix}-#{$i} {
				font-size: #{$i}px !important;
			}

			.lh#{$infix}-#{$i} {
				line-height: #{$i}px !important;
			}
		}
	}
}

/* Text Hover Color */
@each $color, $var in $theme-colors {
	.text-hover-#{$color} {
		&:hover {
			color: $var $i;
		}
	}

	.bg-hover-#{$color} {
		&:hover {
			background-color: $var $i;
		}
	}

	.btn-check:checked {
		& + .bg-checked-#{$color} {
			background-color: $var $i;
		}

		& + .border-checked-#{$color} {
			border-color: $var $i;
		}

		& + .text-checked-#{$color} {
			color: $var $i;
		}
	}
}
