.flickity-enabled {
	position: relative;

	&:focus {
		outline: none;
	}

	&.is-draggable {
		-webkit-tap-highlight-color: transparent;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.flickity-viewport {
			cursor: move;
			cursor: -webkit-grab;
			cursor: grab;

			&.is-pointer-down {
				cursor: -webkit-grabbing;
				cursor: grabbing;
			}
		}
	}
}

.flickity-viewport {
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

.flickity-button {
	position: absolute;
	background: hsla(0, 0%, 100%, 0.75);
	border: none;
	color: #333;

	&:hover {
		background: white;
		cursor: pointer;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 5px #19F;
	}

	&:active {
		opacity: 0.6;
	}

	&:disabled {
		opacity: 0.3;
		cursor: auto;
		pointer-events: none;
	}
}

.flickity-button-icon {
	fill: currentColor;
}

.flickity-prev-next-button {
	top: 50%;
	width: 52px;
	height: 52px;
	border-radius: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	justify-content: center;

	&.previous {
		left: $grid-gutter-width;
	}

	&.next {
		right: $grid-gutter-width;
	}

	.flickity-button-icon {
		width: 16px;
		height: 16px;
	}
}

.flickity-rtl {
	.flickity-prev-next-button {
		&.previous {
			left: auto;
			right: 10px;
		}

		&.next {
			right: auto;
			left: 10px;
		}
	}

	.flickity-page-dots {
		direction: rtl;
	}
}

.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -30px - 15;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.dot {
		width: 10px;
		height: 10px;
		margin: 0 5px;
		background: $border-color;
		border-radius: 50%;
		cursor: pointer;

		&.is-selected {
			background: $primary;
		}
	}
}

.flickity-gallery {
	&__btn-group {
		padding: 4px;
		position: relative;
		z-index: 1;
	}

	&__btn {
		border-color: transparent;
		padding: 15px;

		i {
			font-size: 24px;
		}
	}

	@include media-breakpoint-down(md) {
		&__btn {
			padding: 10px;

			i {
				font-size: 20px;
			}
		}
	}
}
