.page-tab {
	&__nav {
		border: none;
		margin-bottom: 40px;
	}

	&__item {
	}

	&__link {
		display: block;
		font-size: 18px;
		font-weight: 500;
		padding: 8px 35px;
		border: 1px solid transparent;
		border-radius: 40px;
		transition: all ease 0.3s;
	}


	&__content {
	}

	&__pane {
	}

	@include media-breakpoint-down(sm) {
		&__nav {
			flex-wrap: nowrap;
			overflow-y: scroll;
			justify-content: center;
		}

		&__link {
			font-size: 17px;
			padding: 8px 20px;
		}
	}
}
