.foot-agency {
	$className: foot-agency;
	position: relative;

	a {
		display: block;
	}

	&__logo {
		height: 28px;
	}
}
