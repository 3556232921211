.form-check-label {

	a {
		color: $body-color;
		margin-right: 5px;
		text-decoration: underline;
	}
}

[data-color-area] {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 50%;
	left: 20px;
	transform: translate(0, -50%);
	background: #eff6fa;
	border: 1px solid #dde5e9;
}
