.home-promo {

	position: relative;
	background: $gray-500;
	margin: 0 rfs-fluid-value($header-padding);

	@each $breakpoint, $size in $header-height {
		@include media-breakpoint-up($breakpoint) {
			height: calc(100vh - #{$size});
		}
	}

	&__item {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;

		&.is-selected {
			z-index: 1;
		}
	}

	&__bg {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__nav {
		position: absolute;
		z-index: 1;
		width: 100%;
		left: 0;
		bottom: rfs-fluid-value($header-padding);
	}


	@include media-breakpoint-down(xl) {
		margin: 0;
	}
}