.sidebar-widget {
	position: relative;
	padding: 30px;
	border: 1px solid $gray-300;
	background: #fff;


	&__head {
		border-bottom: 1px solid $gray-300;
		margin: -30px;
		margin-bottom: 0;
		padding: 30px;
	}

	&__title {
		font-size: 18px;
		line-height: 1.2;

	}

	&__body {
		margin-top: 30px;

		p {
			font-size: 15px;
			line-height: 1.4;
			color: $gray-500;
			margin-bottom: 0;
		}
	}


	&--fixed {
		@include media-breakpoint-down(md) {
			position: fixed;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 100%;
			@include border-bottom-radius(0);
			border-left: none;
			border-right: none;
			border-bottom: none;
			transform: translate(0, 100%);
			opacity: 0;
			transition: $transition-base;
			padding-bottom: 100px;

			&.is-active {
				transform: translate(0, 0);
				opacity: 1;
			}
		}
	}
}
