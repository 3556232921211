.sidebar-form {

	position: relative;
	padding: 50px;
	background: $gray-100;

	&__head {
		margin-bottom: 30px;
		max-width: 75%;
	}

	&__body {
	}

	&__title {
		font-size: 25px;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__description {
		font-size: 15px;
		font-weight: 400;
		color: $gray-500;
		margin-top: 10px;
	}


	@include media-breakpoint-down(xl) {
		padding: 30px;

		&__head {
			max-width: inherit;
		}
	}
}
