.foot-social {

	&__list {
		display: flex;
		margin: 0 -5px;
	}

	&__label {
		font-size: 15px;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		margin: 0 5px;
		border-radius: 40px;
		transition: ease 0.3s;
		color: $gray-400;
		border: 1px solid $white;
		background: $white;

		&:hover {
			background: $secondary;
			color: #fff;
			box-shadow: 0 8px 12px 0 #d1daff;
		}
	}

	&__icon {
		font-size: 16px;
	}


	@include media-breakpoint-down(md) {
		&__list {
			justify-content: center;
		}
	}
}
