.contact-info {

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

	}

	li {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 6px 0;
	}

	b {
		min-width: 120px;
		font-weight: 500;
	}

	a {
		color: $body-color;

		&:hover {
			text-decoration: underline;
		}

		&:before {
			content: ':';
			margin-right: 10px;
		}
	}

}
