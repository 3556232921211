.head-menu {
	$className: head-menu;

	display: flex;
	align-items: center;

	&__item {
		padding: 0 $grid-gutter-width / 2;

		&-link {
			position: relative;
			display: flex;
			align-items: center;
			text-align: center;
			font-size: 16px;
			line-height: 1.3;
			font-weight: 500;
			color: $primary;
			padding: 25px 0;
			margin: 0;

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				height: 2px;
				background: $secondary;
				width: 0;
				transition: all ease 0.3s;
			}

			&:hover, &.active, &.is-active {
				text-decoration: none;
				border-bottom-color: $secondary;
				color: $secondary;

				&:before {
					width: 50%;
				}
			}
		}
	}

	@include media-breakpoint-down(xl) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&__item {
			width: 100%;

			&-link {
				margin: 0;
				font-size: 20px;
				color: $body-color;
				padding: 10px 0;

				&:hover, &.is-active {
				}
			}
		}
	}


	&--side {
		flex-direction: column;
		align-items: flex-start;
		margin-left: 0;

		.#{$className}__item {
			padding: 0;

			&-link {
				font-size: 20px;
			}
		}
	}
}
