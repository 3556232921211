.head-language-dropdown {
	display: flex;

	&__btn {
		border-color: transparent;


		.show & {
			border: 1px solid $dropdown-border-color;
			box-shadow: none;
		}
	}

	&__dropdown {
		min-width: inherit;
		width: 100%;
		margin-top: -2px;
	}

	&__item {
		font-weight: 700;
		font-size: 15px;

		&.is-active {
		}

		&:hover {
		}
	}
}
