.foot-bulletin {

	&__title {
		font-size: 18px;
		line-height: 1.2;
	}

	&__sub-title {
		line-height: 1.2;
	}

	&__form {
		position: relative;

		.btn {
			position: absolute;
			top: 8px;
			right: 8px;
			bottom: 8px;

			i {
				transition: all ease 0.3s;
			}

			&:hover {
				background: $primary;

				i {
					@include wobble(infinite);
				}
			}
		}
	}
}
