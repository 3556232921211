.sidebar-menu {

	&__item {
		font-size: 16px;
		color: $gray-500;
		transition: all ease 0.3s;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
		width: 100%;
		box-shadow: none $i;

		&:after {
			@include fi($fi-arrow-right);
			transform: translate(0, 0);
			opacity: 0;
			transition: all ease 0.3s;
		}

		&:hover, &.active {
			color: $body-color;
			font-weight: 600;

			&:after {
				transform: translate(-20px, 0);
				opacity: 1;
			}
		}

		&:hover {
			color: $body-color;
		}
	}

	@include media-breakpoint-down(lg) {
		&__item {
			padding: 10px 0;
		}
	}

}
