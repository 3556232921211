.faq {
	&__item {
		border: 1px solid $gray-300 $i;
		border-radius: $border-radius-lg;
		overflow: hidden;
		margin-bottom: 10px;
	}

	&__head {
	}

	&__body {
		background: $gray-100;
	}

	&__btn {
		font-size: 17px;
		font-weight: 500;
		background: none;

		&:after {
			background: $gray-100 $i;
			width: auto;
			height: auto;
			font-size: 16px;
			line-height: 16px;
			@include fi($fi-angle-down-2);
			color: $primary;
			padding: 10px;
			margin: -4px 0;
			margin-left: auto;
			border-radius: 50px;
		}

		&:not(.collapsed) {
			background: $gray-100;
		}
	}


	@include media-breakpoint-down(md) {

		&__btn, &__body-inner {
			padding: 15px 20px;
		}
	}
}
