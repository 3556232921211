.foot-menu {
	$className: foot-menu;

	display: flex;
	margin: 0 -15px;

	&__item {
		width: 100%;
		padding: 0 15px;

		&-link {
			font-size: 16px;
			color: $primary;
			font-weight: 500;
		}
	}

	&__children {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		&-link {
			font-size: 12px;
			color: $gray-500;
			padding: 5px 0;
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		margin: 0;
		text-align: center;

		&__item {
			padding: 0;

			& + & {
				margin-top: 30px
			}
		}
	}

	@include media-breakpoint-only(md) {
		margin: 0 -15px;

		&__item {
			padding: 0 15px;
		}

		&__children {
		}
	}

	@include media-breakpoint-only(lg) {
		margin: 0 -20px;

		&__item {
			padding: 0 20px;
		}
	}


	&--corporate {
		@include media-breakpoint-between(md, xl) {
			.#{$className}__children {
				flex-direction: row;
				flex-wrap: wrap;

				&-link {
					width: 50%;
				}
			}
		}
	}
}

.kvkk-menu {
	display: flex;
	margin: 0 ($grid-gutter-width / 2) * -1;

	&__item {
		padding: 0 ($grid-gutter-width / 2);

		&-link {
			font-size: 14px;
			color: $gray-500;
		}
	}
}
