.contact-form {
	&__head {
	}

	&__body {
	}

	&__title {
	}

	&__description {
	}
}
