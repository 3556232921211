.head-side-menu {

	position: fixed;
	z-index: 12;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-width: 620px;
	background: #fff;
	padding: rfs-fluid-value($header-padding);
	box-shadow: $box-shadow-lg;
	display: none;
	flex-direction: column;

	transition: $transition-base;
	transform: translate(-100%, 0);
	opacity: 0;

	&.is-active {
		display: flex;

		&.is-show {
			transform: translate(0);
			opacity: 1;
		}
	}

	@each $breakpoint, $size in $header-height {
		@include media-breakpoint-up($breakpoint) {
			padding-top: $size + 30;
		}
	}

	@include media-breakpoint-down(xl) {
		max-width: 70%;
		overflow-y: scroll;
	}

	@include media-breakpoint-down(md) {
		max-width: 80%;
	}

}
