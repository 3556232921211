.page-breadcrumb {
	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	&__link {
		font-size: 12px;
		line-height: 1.4;
		color: $gray-400;

		&:after {
			@include fi($fi-angle-right);
			font-size: 8px;
			display: inline-block;
			margin: 0 8px;
		}

		&:last-child {
			color: darken($gray-400, 10%);

			&:after {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		&__list {
			flex-wrap: nowrap;
			overflow-x: scroll;
			white-space: nowrap;
			padding-bottom: 10px;
		}
	}
}
