.product-properties {
	$padding: 40px;

	background: $gray-100;
	padding: ($padding / 2) 0;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
	}

	li {
		width: 50%;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ($padding / 2) ($padding);
		color: $primary;
		border-bottom: 1px solid $gray-300;
		margin: -1px 0;
		font-weight: 500;
	}

	strong, b {
		font-size: 16px;
		font-weight: 400;
		color: $gray-600;
	}

	@include media-breakpoint-down(sm) {
		padding: 10px 0;

		li {
			width: 100%;
			padding: 10px 20px;
			font-size: 16px;
		}

		strong , b {
			font-size: 14px;
		}
	}
}
