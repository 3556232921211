.head-search {
	$className: head-search;

	position: relative;

	&__form {
		display: flex;
		align-items: center;
	}

	&__input-box {
	}

	&__input {
	}

	&__btn {
		position: absolute;
		top: 3px;
		right: 3px;
		bottom: 3px;
		padding-top: 0;
		padding-bottom: 0;
	}

	&__icon {
	}
}

