.product-gallery-thumb {

	&__item {
		border-radius: 10px;
		transition: all ease 0.3s;
		border: 1px solid $gray-200;

		.is-selected & {
			background: $gray-100;
			border-color: $gray-300;
		}
	}

}

.product-video-btn {
	position: absolute;
	top: 30px;
	left: 30px;
	width: 64px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;

	i {
		font-size: 16px;
		line-height: 20px;
		width: 20px;
		position: relative;
		z-index: 1;
	}

	.ripple {
		position: absolute;
		width: 120px;
		height: 120px;
		z-index: 0;
		left: 50%;
		top: 50%;
		opacity: 0;
		margin: -60px 0 0 -60px;
		border-radius: 100px;
		animation: ripple 1.8s infinite;
		background: $secondary;

		&:nth-child(2) {
			animation-delay: .3s;
			-webkit-animation-delay: .3s
		}

		&:nth-child(3) {
			animation-delay: .6s;
			-webkit-animation-delay: .6s
		}
	}

	@include media-breakpoint-down(md) {
		width: 50px;
		height: 50px;
		top: 20px;
		left: 20px;

		i {
			font-size: 12px;
		}

		.ripple {
			width: 80px;
			height: 80px;
			margin: -40px 0 0 -40px;
		}
	}
}

@-webkit-keyframes ripple {
	0% {
		opacity: 1;
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

@keyframes ripple {
	0% {
		opacity: 1;
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1)
	}
}

