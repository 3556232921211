.hamburger-inner {
	&:before {
		width: 75%;

		.is-active & {
			width: 100%;
		}
	}

	&:after {
		width: 50%;

		.is-active & {
			width: 100%;
		}
	}
}
