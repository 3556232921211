/* ===== Theme Variable */
@import "theme/variable";
@import "theme/mixin";
@import "bootstrap-custom/bootstrap-func";
@import "bootstrap-custom/bootstrap-var";
@import "theme/plugin-variable";
@import "theme/icon-font";

/* ===== Node Modules */
@import "vendor";

/* ===== Bootstrap */
@import "bootstrap-custom/bootstrap";

/* ===== Other Plugin */
@import "plugins/fancybox";
@import "plugins/flickity";
@import "plugins/slick";
@import "plugins/toastr";

/* ===== Partials */
@import "partials/header";
@import "partials/footer";

/* ===== Global */
@import "theme/helper";
@import "theme/global";
@import "theme/theme";

/* ===== Global */
@import "../components/box-group/box-group";
@import "../components/page-breadcrumb/page-breadcrumb";
@import "../components/page-head/page-head";
@import "../components/page-article/page-article";
@import "../components/page-sidebar/page-sidebar";
@import "../components/page-tab/page-tab";
@import "../components/page-content/page-content.scss";

/* ===== Header */
@import "../components/head-menu/head-menu";
@import "../components/head-language/head-language";
@import "../components/head-language-dropdown/head-language-dropdown";
@import "../components/head-hamburger/head-hamburger";
@import "../components/head-search/head-search";
@import "../components/head-dropdown-menu/head-dropdown-menu";
@import "../components/head-contact/head-contact";

/* ===== Footer */
@import "../components/foot-bulletin/foot-bulletin";
@import "../components/foot-menu/foot-menu";
@import "../components/foot-social/foot-social";
@import "../components/foot-address/foot-address";
@import "../components/foot-copyright/foot-copyright";
@import "../components/foot-agency/foot-agency";

/* ===== Sidebar */
@import "../components/sidebar-widget/sidebar-widget";
@import "../components/sidebar-form/sidebar-form";
@import "../components/sidebar-menu/sidebar-menu";

/* ===== Component */
@import "../components/gallery/gallery";
@import "../components/faq/faq";

/* ===== Contact */
@import "../components/contact-map/contact-map";
@import "../components/contact-form/contact-form";
@import "../components/contact-address/contact-address";

/* ===== Theme */
@import "resources/assets/custom-components/foot-contact/foot-contact.scss";
@import "resources/assets/custom-components/head-side-menu/head-side-menu.scss";
@import "resources/assets/custom-components/reference-list/reference-list.scss";
@import "resources/assets/custom-components/contact-info/contact-info.scss";
@import "resources/assets/custom-components/team-list/team-list.scss";
@import "resources/assets/custom-components/product-gallery/product-gallery.scss";
@import "resources/assets/custom-components/product-properties/product-properties.scss";
@import "resources/assets/custom-components/cart/cart.scss";
@import "resources/assets/custom-components/home-promo/home-promo.scss";