.site-cart {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 5;
	transition: all ease 0.3s;
	transform: translate(0, 100%);

	&__toggle-btn {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 50%;
		transform: translate(-50%, -100%);
		background: #fff;
		border: none;
		color:$primary;
		@include border-top-radius(50px);
		@include border-bottom-radius(0);

		@include media-breakpoint-down(md) {
			.is-active & {
				i {
					transform: rotate(180deg);
				}
			}
		}
	}

	&.is-active {
		transform: translate(0, 0);
	}
}