.page-head {
	$className: page-head;

	position: relative;
	margin: rfs-fluid-value(30px) 0;

	&--border {

		.#{$className}__inner {
			border-bottom: 1px solid $gray-200;
			padding-bottom: rfs-fluid-value(30px);
		}
	}

	@include media-breakpoint-down(md) {
	}
}
