.site-head {
	padding: 0 rfs-fluid-value($header-padding);
}

.head-block {
	@include media-breakpoint-down(xl) {
		&--left {
			position: fixed;
			z-index: 13;
			top: 0;
			left: 0;
			width: 100%;
			padding: 0 20px;
			box-shadow: 0 5px 16px 0 rgba($black, 0.05);
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&--right {
			position: fixed;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-width: 300px;
			padding: 20px;
			padding-top: map-get($header-height, md) + 20;
			background: #fff;
			box-shadow: 5px 0 16px 0 rgba($black, 0.05);
			transform: translate(calc(-100% - 20px), 0);
			opacity: 0;
			transition: all ease 0.3s;

			&.is-active {
				transform: translate(0, 0);
				opacity: 1;
			}
		}
	}
}

.head-logo {
	display: flex;
	align-items: center;
	margin-right: rfs-fluid-value($header-padding);

	&__link {
	}

	&__image {
	}

	@include media-breakpoint-down(xl) {
		&__image {
		}
	}
}

.head-cart {
	position: relative;

	&__badge {
		padding: 0;
		position: absolute;
		top: 5px;
		right: 5px;
		width: 18px;
		height: 18px;
		border-radius: 18px;
		font-size: 11px;
		line-height: 18px;
		font-weight: 400;
	}
}