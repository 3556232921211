.foot-contact {
	display: flex;
	align-items: center;
	line-height: 1;

	&__label {
		font-size: 15px;
		font-weight: 400;
	}

	&__value {
		font-size: 25px;
		font-weight: 500;
		margin-top: 5px;
	}

	&__icon {
		font-size: 50px;
	}

	@include media-breakpoint-down(md) {
		justify-content: center;

		&__value {
			font-size: 20px;
		}

		&__icon {
			font-size: 40px;
		}
	}
}
