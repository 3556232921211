.box-group {
	$className: box-group;

	&__title {
	}

	&__sub-title {
	}

	&__description {
	}

	&__btn {
	}


	&--bg {
		margin-left: rfs-fluid-value($header-padding);
		margin-right: rfs-fluid-value($header-padding);

		@include media-breakpoint-down(xl) {
			margin: 0;
		}
	}
}
