#toast-container {
	> div {
		opacity: 1;
	}

	> .toast-success {
		background-image: none $i;
	}
}

.toast {
	box-shadow: none $i;
	border: none $i;
	border-radius: 8px $i;
	padding: 20px $i;
	transition: background-color ease 0.3s;

	&-top-right {
		top: 30px;
		right: 30px;
	}

	&-success {
		background-color: rgba($green, 1);

		&:hover {
			background-color: rgba($green, 1);
		}
	}

	&-title {
		font-size: 16px;
		font-weight: 400;
	}

	&-message {
		font-size: 14px;
		font-weight: 300;
		margin-top: 5px;
	}
}