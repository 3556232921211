.dropdown {
	&-menu {
		box-shadow: $dropdown-box-shadow;
	}

	&-toggle {
		display: flex;
		align-items: center;
		justify-content: center;
		&:after {
			border: none;
			font-size: 12px;
			margin-left: 15px;
			@include fi($fi-angle-down);
		}
	}
}
